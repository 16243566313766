import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { WishlistItemFragmentDoc } from './WishlistItemFragment.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetWishlistItemQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type GetWishlistItemQuery = {
  __typename?: 'Query';
  wishlistItem?: {
    __typename?: 'WishlistItem';
    id: string;
    inWishlist: boolean;
  } | null;
};

export const GetWishlistItemDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWishlistItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wishlistItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'wishlistItem' },
                },
              ],
            },
          },
        ],
      },
    },
    ...WishlistItemFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetWishlistItemQuery__
 *
 * To run a query within a React component, call `useGetWishlistItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWishlistItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWishlistItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWishlistItemQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetWishlistItemQuery,
    GetWishlistItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetWishlistItemQuery,
    GetWishlistItemQueryVariables
  >(GetWishlistItemDocument, options);
}
export function useGetWishlistItemLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetWishlistItemQuery,
    GetWishlistItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetWishlistItemQuery,
    GetWishlistItemQueryVariables
  >(GetWishlistItemDocument, options);
}
export type GetWishlistItemQueryHookResult = ReturnType<
  typeof useGetWishlistItemQuery
>;
export type GetWishlistItemLazyQueryHookResult = ReturnType<
  typeof useGetWishlistItemLazyQuery
>;
export type GetWishlistItemQueryResult = Apollo.QueryResult<
  GetWishlistItemQuery,
  GetWishlistItemQueryVariables
>;
