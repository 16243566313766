import React from 'react';
import styled from 'styled-components';
import { useWishlistOffer } from 'components/domain/wishlist/useWishlistOffer';
import WishlistIcon from 'components/domain/wishlist/WishlistIcon';
import WishlistLoginRecommenderModal from './loginRecommender/WishlistLoginRecommenderModal';
import { WishlistSource } from './Wishlist.constants';

export interface IFavourableContainerProps extends React.PropsWithChildren<{}> {
  offerId: string;
  inWishlist: boolean;
  productId?: string;
  source?: WishlistSource;
  withLabel?: boolean;
  withTooltip?: boolean;
  onClick?(e: React.MouseEvent<HTMLElement>, isActive: boolean): void;
  className?: string;
}

const FavourableContainerWrapper = styled.div({
  position: 'relative',
});

const FavourableContainer: React.FC<IFavourableContainerProps> = ({
  offerId,
  inWishlist,
  productId,
  source = null,
  withLabel,
  withTooltip,
  onClick,
  children,
  className,
}) => {
  const {
    isActive,
    loading,
    onWishlistIconClick,
    setLoginRecommenderFormModalClose,
    isWishlistLoginRecommenderModalShowed,
  } = useWishlistOffer(offerId, source, inWishlist, productId);

  function clickHandler(e: React.MouseEvent<HTMLElement>) {
    onWishlistIconClick();
    onClick && onClick(e, isActive);
  }

  return (
    <FavourableContainerWrapper className={className}>
      {children}
      <WishlistIcon
        id={offerId}
        isActive={isActive}
        withLabel={withLabel}
        withTooltip={withTooltip}
        onClick={clickHandler}
        loading={loading}
      />
      <WishlistLoginRecommenderModal
        onClose={setLoginRecommenderFormModalClose}
        show={isWishlistLoginRecommenderModalShowed}
      />
    </FavourableContainerWrapper>
  );
};

export default FavourableContainer;
