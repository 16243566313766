import React from 'react';
import styled from 'styled-components';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import RecommendationItemSkeleton from './RecommendationItem.skeleton';

export const GroupTitleSkeleton: React.FC<{
  className?: string;
  uid: string;
}> = ({ className, uid }) => {
  const { mobile } = useDeviceType();

  return (
    <div className={className}>
      <PlaceholderLoader
        uid={uid}
        width={mobile ? 327 : 1170}
        height={mobile ? 31 : 49}
        style={{ width: '100%', height: mobile ? '31px' : '49px' }}
      >
        {!mobile && (
          <>
            <rect width="1170" height="1" />
            <rect y="22" width="288" height="27" rx="10" />
          </>
        )}

        {mobile && (
          <>
            <rect width="327" height="1" transform="matrix(1 0 0 -1 0 1)" />
            <rect y="11" width="182" height="20" rx="10" />
          </>
        )}
      </PlaceholderLoader>
    </div>
  );
};

export const RecommendationListItemSkeleton: React.FC<{
  uniqueKey?: string;
  className?: string;
}> = ({ className, uniqueKey = `recommendation-list` }) => {
  const { mobile } = useDeviceType();

  return (
    <div className={className}>
      <PlaceholderLoader
        uid={uniqueKey}
        width={mobile ? 327 : 370}
        height={mobile ? 324 : 383}
        style={{
          width: '100%',
          height: mobile ? 'auto' : '383px',
          display: 'block',
        }}
      >
        {!mobile && (
          <>
            <path d="M0 4C0 1.79086 1.79086 0 4 0H366C368.209 0 370 1.79086 370 4V188H0V4Z" />
            <rect y="340" width="370" height="1" />
            <rect y="382" width="344" height="1" />
            <rect x="17" y="245" width="156" height="16" rx="8" />
            <rect x="17" y="212" width="188" height="20" rx="8" />
            <rect x="170" y="352" width="160" height="20" rx="8" />
            <rect x="17" y="274" width="141" height="16" rx="8" />
            <rect x="17" y="300" width="165" height="16" rx="8" />
            <path d="M344 341H370V379C370 381.209 368.209 383 366 383H344V341Z" />
            <rect y="188" width="1" height="194" />
            <rect x="369" y="188" width="1" height="189" />
          </>
        )}

        {mobile && (
          <>
            <path d="M0 4.00001C0 1.79087 1.79086 0 4 0H323C325.209 0 327 1.79086 327 4V159.039H0V4.00001Z" />
            <rect y="287.624" width="327" height="0.845953" />
            <rect y="323.154" width="304.022" height="0.845953" />
            <rect
              x="15.0243"
              y="207.259"
              width="137.87"
              height="13.5352"
              rx="6.76762"
            />
            <rect
              x="15.0243"
              y="179.342"
              width="166.151"
              height="16.9191"
              rx="8"
            />
            <rect
              x="150.243"
              y="297.775"
              width="141.405"
              height="16.9191"
              rx="8"
            />
            <rect
              x="15.0243"
              y="231.791"
              width="124.613"
              height="13.5352"
              rx="6.76762"
            />
            <rect
              x="15.0243"
              y="253.786"
              width="145.824"
              height="13.5352"
              rx="6.76762"
            />
            <path d="M304.022 288.47H327V320C327 322.209 325.209 324 323 324H304.022V288.47Z" />
            <rect y="159.04" width="0.883784" height="164.115" />
            <rect x="326.116" y="159.04" width="0.883784" height="159.885" />
          </>
        )}
      </PlaceholderLoader>
    </div>
  );
};

const SkeletonWrapper = styled.figure.attrs({
  'data-id': `recommendation-skeleton`,
})(
  sx2CssThemeFn({
    display: ['flex', `grid`],
    gridTemplateColumns: [null, `repeat(3, 1fr)`],
    gap: [3, '35px 30px'],
    flexWrap: 'wrap',
    px: [4, 6],
    mx: [0, 'auto'],
    maxWidth: [null, '1280px'],
    mb: '50px',
  })
);

export const TitleSkeleton = styled(GroupTitleSkeleton)(
  sx2CssThemeFn({
    gridColumn: [null, `span 3`],
  })
);

const ListItemSkeleton = styled(RecommendationItemSkeleton)({
  width: '100%',
});

const RecommendationListSkeleton: React.FC<{
  className?: string;
  hasTitle?: boolean;
}> = ({ className, hasTitle }) => {
  const { mobile } = useDeviceType();

  return (
    <SkeletonWrapper className={className}>
      {hasTitle && <TitleSkeleton uid="RecommendationListSkeleton_title" />}
      {!mobile && (
        <>
          <ListItemSkeleton />
          <ListItemSkeleton />
          <ListItemSkeleton />
        </>
      )}
      {mobile && <ListItemSkeleton />}
    </SkeletonWrapper>
  );
};

export default RecommendationListSkeleton;
