import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import RouterLink from '@hotelplan/components.common.router-link';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { PageType } from '@hotelplan/graphql.types';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import { track } from '@hotelplan/libs.tracking-events';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

const ContentWrapper = styled.figure(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.LARGE,
    mb: 4,
    li: {
      listStyle: 'none',
      ml: '24px',
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        left: '-14px',
        top: ['10px', '14px'],
        width: '5px',
        height: '5px',
        backgroundColor: 'black',
        borderRadius: '50%',
      },
    },
  })
);

const ButtonsWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: ['column', 'row'],
  })
);

const ForwardLoginButton = styled(BsButton)(
  sx2CssThemeFn({
    width: '100%',
    mr: [null, 3],
    mb: [3, 0],
  })
);

const getRouteProps = (pageType: PageType, language: string) => {
  const route = getRouteByPageTypeLocale(pageType, language);

  return route
    ? getTargetPageData({
        targetPageType: pageType,
        uri: route.builder({}),
      })
    : null;
};

const ForwardRegisterButton = styled(BsButton)(
  sx2CssThemeFn({ width: '100%' })
);

const WishlistLoginRecommenderForm = () => {
  const [t, { language }] = useTranslation('wishlist');

  const loginRouterProps = useMemo(
    () => getRouteProps(PageType.MyaccountLogin, language),
    [language]
  );

  const registerRouterProps = useMemo(
    () => getRouteProps(PageType.MyaccountRegistration, language),
    [language]
  );

  return (
    <div>
      <ContentWrapper>
        <figcaption>{t('loginRecommender.listTitle')}</figcaption>
        <ul>
          {[...Array(4)].map((_, index) => (
            <li key={index}>{t(`loginRecommender.listText${index + 1}`)}</li>
          ))}
        </ul>
      </ContentWrapper>
      <ButtonsWrapper>
        <RouterLink {...loginRouterProps} passHref>
          <ForwardLoginButton
            onClick={() => {
              if (!loginRouterProps) return;
              track({
                event: `🚀 click.login`,
                eventMetadata: { source: `wishlist.modal` },
                _clear: true,
              });
            }}
          >
            {t('loginRecommender.loginButton')}
          </ForwardLoginButton>
        </RouterLink>
        <RouterLink {...registerRouterProps} passHref>
          <ForwardRegisterButton
            variant={E_BUTTON_TYPE.SECONDARY}
            onClick={() => {
              if (!registerRouterProps) return;
              track({
                event: `🚀 click.register`,
                eventMetadata: { source: `wishlist.modal` },
                _clear: true,
              });
            }}
          >
            {t('loginRecommender.registerButton')}
          </ForwardRegisterButton>
        </RouterLink>
      </ButtonsWrapper>
    </div>
  );
};

export default WishlistLoginRecommenderForm;
