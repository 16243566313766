import noop from 'lodash/noop';
import { useTranslation } from 'next-i18next';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useCombinedPagination } from '@hotelplan/libs.hooks';
import { useOnScrolledReach, ScrollDirection } from '@hotelplan/libs.hooks-dom';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { sx2CssTheme, sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import RecommendationItemSkeleton from './RecommendationItem.skeleton';
import RecommendationListSkeleton from './RecommendationList.skeleton';
import { recommendationsItemCss } from './RecommendationList.styles';

export interface IRecommendationListProps<T> {
  recommendations: T[];
  render(item: T, index: number): React.ReactNode;
  loading?: boolean;
  perPage?: number | null;
  page?: number;
  total?: number;
  onFetchMore?(): void;
  onInit?(recommendations: T[]): void;
  className?: string;
}

const RecommendationWrap = styled.section<{ full?: boolean }>(
  ({ full, theme: { FONT_SIZE } }) =>
    sx2CssThemeFn({
      display: ['flex', `grid`],
      gridTemplateColumns: [null, full ? null : `repeat(3, 1fr)`],
      gap: [3, '45px 30px'],
      gridAutoFlow: ['column', 'row'],
      overflowX: ['auto', 'initial'],
      mr: [-4, '0px'],
      mt: [3, '35px'],
      '&&& .card-title': FONT_SIZE.S,
      '&&& .card-subtitle': FONT_SIZE.LARGE,
    })
);

// @TODO: What the purpose of this element?
const WrapFixMobile = styled.div(
  sx2CssThemeFn({
    pl: 1,
  })
);

const recommendationSkeletonStyles = sx2CssThemeFn({
  pl: [null, '15px'],
  width: '100%',
  flexShrink: ['0', null],
});

const showMoreLinkIconStyles = ({ theme }) =>
  sx2CssTheme(theme, {
    verticalAlign: 'middle',
  });

const loadMoreButtonStyles = ({ theme }) =>
  sx2CssThemeFn({
    pt: [4, '37px'],
    gap: 2,
    ...theme.FONT_SIZE.LINK_LARGE,
    '.icon': {
      width: '16px',
      height: '16px',
    },
  });

const ITEMS_PER_PAGE = 3;

const ItemWrap = styled.div<{
  single: boolean;
  mobile: boolean;
}>(recommendationsItemCss, ({ single, mobile }) => ({ theme: { media } }) =>
  mobile
    ? {
        maxWidth: single ? '100%' : '290px',
        width: '100%',
        [media.mobile]: { maxWidth: single ? '100%' : '90%' },
        gridColumn: single ? 'span 3' : null,
      }
    : { gridColumn: single ? 'span 3' : null }
);

const ItemSkeleton = styled(RecommendationItemSkeleton).attrs({
  uniqueKey: 'recommendationItemSkeleton',
})({ ...recommendationSkeletonStyles, maxWidth: '290px' });

const ListSkeleton = styled(RecommendationListSkeleton)({ width: '100%' });

const MoreLessButtons = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

export const ShowMoreButton = styled(BsButton).attrs({
  variant: E_BUTTON_TYPE.LINK_BUTTON,
  'data-id': `load-more-btn-id`,
})(loadMoreButtonStyles, sx2CssThemeFn({ '.icon': showMoreLinkIconStyles }));

export const ShowLessButton = styled(BsButton).attrs({
  variant: E_BUTTON_TYPE.LINK_BUTTON,
  'data-id': `load-less-btn-id`,
})(loadMoreButtonStyles, {
  '.icon': { ...showMoreLinkIconStyles, transform: 'rotate(180deg)' },
});

const RecommendationList = <T extends { __typename?: string }>({
  recommendations,
  render,
  page: currentPage,
  total,
  perPage = ITEMS_PER_PAGE,
  loading,
  onFetchMore,
  className,
  onInit = noop,
}: IRecommendationListProps<T>) => {
  const { mobile } = useDeviceType();
  const [t] = useTranslation('common');

  const { items, nextPage, prevPage, hasMore, page } = useCombinedPagination(
    recommendations,
    {
      page: currentPage,
      total,
      perPage: perPage,
      fetchMore: onFetchMore,
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onInit(recommendations), []);

  const scrollElRef = useOnScrolledReach(nextPage, {
    direction: ScrollDirection.HORIZONTAL,
    enable: mobile && hasMore,
  });

  return (
    <>
      <RecommendationWrap ref={scrollElRef} className={className}>
        {items.map((item, i) => {
          const renderedRecommendation = render(item, i);
          const isMarketing = item.__typename === 'MarketingRecommendationItem';
          const isProduct = item.__typename === 'ProductRecommendationItem';
          const singleItem = items.length === 1 && (isMarketing || isProduct);

          return renderedRecommendation ? (
            <ItemWrap
              key={i}
              single={singleItem}
              mobile={mobile}
              className="recommendations-item"
            >
              {renderedRecommendation}
            </ItemWrap>
          ) : null;
        })}
        {!!nextPage && loading && (
          <>
            {mobile && <ItemSkeleton />}
            {!mobile && <ListSkeleton className="list-skeleton" />}
          </>
        )}
        {items.length !== 1 && mobile ? <WrapFixMobile /> : null}
      </RecommendationWrap>
      {!mobile && (hasMore || page > 0) ? (
        <MoreLessButtons>
          {hasMore ? (
            <ShowMoreButton onClick={nextPage}>
              {t('common:moreOffers.button')}
              <Icon name="chevron-down" />
            </ShowMoreButton>
          ) : (
            <span />
          )}
          {page > 0 && (
            <ShowLessButton onClick={prevPage}>
              {t('common:lessOffers.button')}
              <Icon name="chevron-down" />
            </ShowLessButton>
          )}
        </MoreLessButtons>
      ) : null}
    </>
  );
};

export default RecommendationList;
