import { MutableRefObject } from 'react';
import { useSelfUpdatingRef } from '@hotelplan/libs.hooks-react';
import {
  IWishlistOfferManaging,
  useWishlistCacheManage,
} from './useWishlistCacheManage';
import { useWishlistOnlyOneSelectedOfferContext } from './WishlistOnlyOneSelectedOfferContext';

export function useWishlistOfferManage(): MutableRefObject<IWishlistOfferManaging> {
  const cacheHandlers = useWishlistCacheManage();
  const onlyOneHandlers = useWishlistOnlyOneSelectedOfferContext();
  return useSelfUpdatingRef(
    onlyOneHandlers.provided ? onlyOneHandlers : cacheHandlers
  );
}
