import React from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { SkeletonBaseBlock } from 'components/domain/srl-ui/SRLui.styles';

const PriceBar = styled.div(
  sx2CssThemeFn({
    width: '100%',
    height: ['39px', '43px'],
    backgroundColor: 'white',
    borderTop: '1px solid #f5f6f7',
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: ['10px', '12px'],
    alignItems: 'center',
    marginTop: 'auto',
  })
);

const TextContainer = styled.div(
  sx2CssThemeFn({
    width: '100%',
    padding: ['16px', '16px'],
    rowGap: ['15px', '15px'],
    display: 'flex',
    flexDirection: 'column',
    height: ['209px', '218px'],
  })
);

const Image = styled(SkeletonBaseBlock)(
  sx2CssThemeFn({
    height: ['147px', '188px'],
    flexShrink: 0,
    minHeight: ['147px', '188px'],
    width: '100%',
  })
);

const MainContainer = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    backgroundColor: 'white',
    overflow: 'hidden',
    flexDirection: 'column',
  })
);
const Wrapper = styled.div(
  sx2CssThemeFn({
    height: '100%',
    border: '1px solid #f5f6f7',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: ['90%', '100%'],
    flexShrink: 0,
  })
);

function RecommendationItemSkeleton(): React.ReactElement {
  return (
    <Wrapper>
      <MainContainer>
        <Image />
        <TextContainer>
          <SkeletonBaseBlock style={{ width: '115px' }} />
          <SkeletonBaseBlock style={{ width: '65%' }} />
          <SkeletonBaseBlock style={{ width: '70%' }} />
          <SkeletonBaseBlock style={{ width: '70%' }} />
          <SkeletonBaseBlock style={{ width: '55%' }} />
          <SkeletonBaseBlock style={{ width: '60%' }} />
        </TextContainer>
      </MainContainer>
      <PriceBar>
        <SkeletonBaseBlock width={['140px', '160px']} />
        <SkeletonBaseBlock height="100%" width={['24px', '26px']} />
      </PriceBar>
    </Wrapper>
  );
}

export default RecommendationItemSkeleton;
