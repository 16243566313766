import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { AuthChannelType } from '@hotelplan/graphql.local-types';
import {
  Price,
  ProductFeatureGroup,
  TravelType,
} from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import {
  getTargetPageData,
  TOpenMethod,
} from '@hotelplan/libs.router-link-utils';
import { formatDate, parseDateStr, formatTestId } from '@hotelplan/libs.utils';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import OfferItem from 'components/domain/OfferItem';
import { getProductIdFromUrl } from 'components/domain/product/Product.utils';
import { filterElementPlacementById } from 'components/domain/srl/SRL.mappers';
import { SRLProductCardVariants } from 'components/domain/srl/SRL.types';
import { getProductIcons } from 'components/domain/travelTypes/TravelType.utils';
import FavourableContainer, {
  IFavourableContainerProps,
} from 'components/domain/wishlist/FavourableContainer';
import { WishlistSource } from 'components/domain/wishlist/Wishlist.constants';
import { ProductItemFragment } from 'graphql/srl/SRLFragments.generated';

interface ISRLProductItemProps {
  openMethod?: TOpenMethod;
  product: ProductItemFragment;
  wishlistSource?: WishlistSource;
  closeButton?: React.ReactNode | null;
  onClose?(): void;
  onClick?(): void;
  className?: string;
  productCardVariant?: SRLProductCardVariants;
  travelType?: TravelType;
  isThirdProductCardVariant?: boolean;
}

const ProductFavContainer = styled(FavourableContainer)<{
  withDisrupter?: boolean;
}>(({ withDisrupter }) =>
  sx2CssThemeFn({
    '.wishlist-icon': {
      zIndex: 1,
      right: '15px',
      top: withDisrupter ? ['204px', '61px'] : ['159px', '11px'],
      position: 'absolute',
    },
    '.disrupter': {
      fontSize: [2, '18px'],
      marginBottom: '-3px',
      paddingBottom: ['13px', 3],
      paddingTop: ['10px', 3],
    },
  })
) as React.FC<IFavourableContainerProps & { withDisrupter: boolean }>;

const SRLProductItem: React.FC<ISRLProductItemProps> = props => {
  const {
    product,
    wishlistSource,
    closeButton,
    className,
    onClose,
    openMethod,
    productCardVariant,
    travelType,
    isThirdProductCardVariant,
    onClick,
  } = props;
  const [t] = useTranslation('common');
  const { mobile } = useDeviceType();

  const { channelType } = useAuthentication();
  const isB2BAuthenticated = channelType === AuthChannelType.B2B;

  return (
    <ProductFavContainer
      withDisrupter={!!product.disrupter}
      withTooltip={!mobile}
      offerId={product.offerId}
      productId={getProductIdFromUrl(product.link.uri)}
      source={wishlistSource}
      className="product-fav-container"
      inWishlist={product.inWishlist}
    >
      <OfferItem
        product={product}
        distanceInfo={product.distanceInfo}
        productFeatures={filterElementPlacementById<ProductFeatureGroup>(
          product.featureSummary,
          `hotel_sustainable`,
          2
        )}
        mealType={product.mealType}
        productClass={product.productClass}
        disrupter={product.disrupter}
        link={getTargetPageData(product.link)}
        openMethod={isB2BAuthenticated ? '_blank' : openMethod}
        price={product.price as Price}
        taReviews={product.taReviews || null}
        taRating={product.taRating || null}
        locations={product.geoBreadcrumbs.map(({ name = '' }) => {
          return { label: name, link: null };
        })}
        productTravelType={getProductIcons(product.type, product.productClass)}
        fromTo={product.fromTo}
        testId={`offer-${formatTestId(product.name)}`}
        departureDate={formatDate(
          parseDateStr(product.departureDate),
          'dd.MM.yyyy'
        )}
        returnDate={formatDate(parseDateStr(product.returnDate), 'dd.MM.yyyy')}
        duration={t('common:nights.count', {
          count: product.duration,
        })}
        className={className}
        closeButton={closeButton}
        onClose={onClose}
        transferPrice={product.transferPrice}
        transferIncluded={product.transferIncluded}
        productCardVariant={productCardVariant}
        travelers={{ adults: product.adults, children: product.children }}
        totalPrice={product.totalPrice as Price}
        travelType={travelType}
        isThirdProductCardVariant={isThirdProductCardVariant}
        onClick={onClick}
      />
    </ProductFavContainer>
  );
};

export default SRLProductItem;
