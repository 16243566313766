import { MutableRefObject } from 'react';
import { IWishlistOfferManaging } from 'components/domain/wishlist/useWishlistCacheManage';
import { AddToActiveWishlistWithSourceMutation } from 'graphql/wishlist/AddToActiveWishlistWithSource.generated';
import { GetWishlistItemQuery } from 'graphql/wishlist/GetWishlistItem.generated';
import { RemoveFromActiveWishlistMutation } from 'graphql/wishlist/RemoveFromActiveWishlist.generated';

export type TPossibleMutation =
  | AddToActiveWishlistWithSourceMutation
  | RemoveFromActiveWishlistMutation;

export const isAddResponse = (
  data: TPossibleMutation
): data is AddToActiveWishlistWithSourceMutation =>
  'addToActiveWishlistWithSource' in data;

export const getResult = (data: TPossibleMutation) => {
  return isAddResponse(data)
    ? data.addToActiveWishlistWithSource
    : data.removeFromActiveWishlist;
};

export const isInWishlist = (data: GetWishlistItemQuery, id: string) =>
  data?.wishlistItem?.id === id && data?.wishlistItem?.inWishlist;

export const updateOffer = (
  data: TPossibleMutation,
  cache: MutableRefObject<IWishlistOfferManaging>,
  id: string
) => {
  if (isAddResponse(data)) {
    const offer = data.addToActiveWishlistWithSource.offer;

    if (!offer) return;

    cache.current.select(offer.id);
    if (offer.id !== id) {
      cache.current.select(id);
    }
  } else {
    cache.current.update(id, false);
  }
};
