import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import styled from 'styled-components';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';
import { AuthChannelType } from '@hotelplan/graphql.local-types';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import WishlistLoginRecommenderForm from './WishlistLoginRecommenderForm';

const WishlistLoginRecommenderModalWrapper = styled(WhiteBoxModal).attrs({
  name: `WishlistLoginRecommenderForm`,
})(({ theme: { FONT_SIZE, modals } }) =>
  sx2CssThemeFn({
    '.modal-wrapper': {
      ...modals.mediumModalWrapper,
      alignSelf: 'center',
      mx: [3, 0],
      '.modal-title': FONT_SIZE.S,
    },
    '.modal-body': { px: 4, pt: 0, pb: 4 },
    '.modal-header': { border: 'none', px: 4, py: 4 },
  })
);

const WishlistLoginRecommenderModal: FC<{
  onClose: () => void;
  show: boolean;
}> = ({ onClose, show }) => {
  const [t] = useTranslation('wishlist');
  const { channelType } = useAuthentication();

  if (channelType !== AuthChannelType.B2CAnonymous) return null;

  return (
    <WishlistLoginRecommenderModalWrapper
      title={t('loginRecommender.title')}
      show={show}
      onClose={onClose}
    >
      <WishlistLoginRecommenderForm />
    </WishlistLoginRecommenderModalWrapper>
  );
};

export default WishlistLoginRecommenderModal;
