import { useApolloClient } from '@apollo/client';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { AuthChannelType, Status } from '@hotelplan/graphql.types';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { useViewedProductContext } from 'components/domain/viewed-product-tracking/ViewedProductContext';
import {
  TPossibleMutation,
  getResult,
  updateOffer,
  isInWishlist,
  isAddResponse,
} from 'components/domain/wishlist/useWishlistOffer.utils';
import {
  AddToActiveWishlistWithSourceMutationVariables as TAddMutationVars,
  useAddToActiveWishlistWithSourceMutation as useAddMutation,
} from 'graphql/wishlist/AddToActiveWishlistWithSource.generated';
import { useGetActiveWishlistLazyQuery } from 'graphql/wishlist/GetActiveWishlist.generated';
import { useGetWishlistItemLazyQuery } from 'graphql/wishlist/GetWishlistItem.generated';
import {
  RemoveFromActiveWishlistMutationVariables as TRemoveMutationVars,
  useRemoveFromActiveWishlistMutation as useRemoveMutation,
} from 'graphql/wishlist/RemoveFromActiveWishlist.generated';
import { useWishlistOfferManage } from './useWishlistOfferManage';
import { WishlistSource } from './Wishlist.constants';
import { removeWishlistActiveCache } from './Wishlist.utils';

export const refetchQueries = [
  `GetWishlistOverview`,
  `GetActiveWishlist`,
  `GetWishlistComponents`,
];

const WISHLIST_LOGIN_RECOMMENDER_COOKIE_NAME =
  'WISHLIST_LOGIN_RECOMMENDER_SHOWED';
const ONE_MONTH = 30;

const useAddToWishlist = (
  offerId: string,
  source: WishlistSource | null = null,
  onCompleted: (data: TPossibleMutation) => void
): [() => ReturnType<typeof add>, boolean] => {
  const variables: TAddMutationVars = { offerId: { offerId, source } };

  const [add, { loading: adding }] = useAddMutation({
    variables,
    refetchQueries,
    onCompleted,
  });

  return [() => add({ variables }), adding];
};

const useRemoveFromWishlist = (
  offerId: string,
  onCompleted: (data: TPossibleMutation) => void
): [() => ReturnType<typeof remove>, boolean] => {
  const variables: TRemoveMutationVars = { offerId };

  const [remove, { loading: deleting }] = useRemoveMutation({
    variables,
    refetchQueries,
    onCompleted,
  });

  return [() => remove({ variables }), deleting];
};

export function useWishlistOffer(
  offerId: string,
  source: WishlistSource | null = null,
  inWishlist?: boolean,
  productId?: string
) {
  const client = useApolloClient();
  const cacheManageRef = useWishlistOfferManage();
  const { channelType } = useAuthentication();
  const [getWishlist, { data: refreshedData }] = useGetWishlistItemLazyQuery();
  const [queryActiveWishlist] = useGetActiveWishlistLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [isActive, seIstActive] = useState(inWishlist);
  const [
    isWishlistLoginRecommenderModalShowed,
    setLoginRecommenderFormModalOpen,
    setLoginRecommenderFormModalClose,
  ] = useToggleState(false);
  const viewedProductContext = useViewedProductContext();

  const isB2CAnonymous = channelType === AuthChannelType.B2CAnonymous;

  useEffect(() => {
    if (refreshedData) {
      seIstActive(isInWishlist(refreshedData, offerId));
    }
  }, [refreshedData]);

  useEffect(() => {
    if (offerId && inWishlist === undefined) {
      getWishlist({ variables: { id: offerId } });
    }
  }, [offerId]);

  function onCompleted(data: TPossibleMutation) {
    const result = getResult(data);
    if (result.status !== Status.Success) {
      // eslint-disable-next-line no-console
      console.error(result.message);
      return;
    }

    removeWishlistActiveCache(client.cache);
    queryActiveWishlist();
    updateOffer(data, cacheManageRef, offerId);
    getWishlist({ variables: { id: offerId } });

    if (isAddResponse(data) && productId) {
      viewedProductContext.trackAddedToWishlist(productId);
    }
  }

  const [add, adding] = useAddToWishlist(offerId, source, onCompleted);
  const [remove, deleting] = useRemoveFromWishlist(offerId, onCompleted);

  const onToggle = async () => {
    await (isActive ? remove() : add());
  };

  function onWishlistIconClick() {
    onToggle();

    if (!isB2CAnonymous || Cookies.get(WISHLIST_LOGIN_RECOMMENDER_COOKIE_NAME))
      return;

    setLoginRecommenderFormModalOpen();
    Cookies.set(WISHLIST_LOGIN_RECOMMENDER_COOKIE_NAME, 'true', {
      expires: ONE_MONTH,
    });
  }

  return {
    isActive,
    loading: adding || deleting,
    onWishlistIconClick,
    isWishlistLoginRecommenderModalShowed,
    setLoginRecommenderFormModalClose,
  };
}
